// 初始化树结构

export function serializer(data) {
    // 创建映射查找子级
    const map = new Map();
    data.forEach(item => {
        if (!item.children) {
            map.set(item.id, {...item, children: []})
        } else {
            map.set(item.id, {...item})
        }

    });

    data.forEach(item => {
        if (item.parent && !item.children) {
            const children = map.get(item.id);
            const parent = map.get(children.parent.id)
            if (parent) {

                parent.children.push(children)

            }
        }
    });
    // 获取根节点（没有 parent 的节点）
    data = [...map.values()].filter(item => item.parent === null);
    return data
}

//将树级结构转化为一般结构
export function childrenFormat(data) {
    let list = []
    data.forEach(item => {
        list.push(item)
        if (item.children) {
            list.push(...childrenFormat(item.children))
        }
    })
    return list
}

//处理时间格式
export function formatDate(dateString, format)  {
    let date = new Date(dateString)
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, '0'); // 月份是从0开始的，所以要加1
    let day = String(date.getDate()).padStart(2, '0');
    let hours = String(date.getHours()).padStart(2, '0');
    let minutes = String(date.getMinutes()).padStart(2, '0');
    let seconds = String(date.getSeconds()).padStart(2, '0');
    if(format === 'yyyy-MM-dd hh:mm:ss'){
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }else if(format === 'yyyy-MM-dd'){
        return `${year}-${month}-${day}`;
    }
}

export function daysToYears(days) {

    return days / 365;
}

export function measureTime(dateString) {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!regex.test(dateString)) throw new Error('Invalid date format. Please use YYYY-MM-DD.');
    const fullDateString = `${dateString}T00:00:00Z`;
    // 生成开始日期
    const startDate = new Date(fullDateString);
    // 开始年份
    const startYear = startDate.getFullYear();
    // 获取当前时间戳
    const dateNow = Date.now();
    // 获取当前日期
    const endDate = new Date(dateNow);
    // 获取当前年份
    const endYear = endDate.getFullYear();
    // 遍历除今年外有几个闰年 如果年份能被4整除但不能被100整除，则为闰年。如果年份能被400整除，也是闰年。
    let leapYearCount = 0;
    for (let year = startYear; year < endYear; year++) {
        if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
            leapYearCount++;
        }
    }

    const dayDate = dateNow - startDate.getTime();
    // 总天数
    const day = Math.floor(dayDate / (1000 * 60 * 60 * 24));
    // 计算剩余天数
    const dayRemaining = day % 365 + leapYearCount

    return Math.floor(day / 365) + '年' + dayRemaining + '天';
}

export function generateToc(data) {
    let dom = new DOMParser();
    let html = dom.parseFromString(data, 'text/html')
    let newParagraph = document.createElement('div');


    let headings = html.querySelectorAll('h1, h2, h3, h4, h5, h6');

    let tocItems = []

    headings.forEach((heading, index) => {
        const id = heading.id || `toc-item-${index}`;
        heading.id = id; // 如果标题没有ID，则给它分配一个

        tocItems.push({
          id,
          text: heading.textContent.trim(),
          level: parseInt(heading.tagName.charAt(1), 10) // 获取标题级别
        });
    });

    for (let i = 0; i < html.body.childNodes.length; i++) {
        let childNode = html.body.childNodes[i];
        let clonedNode = childNode.cloneNode(true);
        newParagraph.appendChild(clonedNode)
    }

    return { 'newEl':newParagraph.outerHTML, 'toc':tocItems}
}



