<template>
    <div class="footer">
        <div>
            <a href="https://beian.miit.gov.cn/">渝ICP备2020010248号-1</a>
        </div>
        <div>
            <a target="_blank" href="http://www.beian.gov.cn/">
                <img src="@/assets/img/备案图标.png" alt="#"/>
                渝公网安备 50023502000372号
            </a>
        </div>

    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.footer {
    text-align: center;
    position: fixed;
    width: 100%;
    bottom: 0;
    height: 50px;
    //padding: 10px 0 0 0;
    //opacity: 0.5;
    isolation: isolate;
    margin: 0 -20px 0 -20px;
    font-size: 14px;
    background-color: #f2f6fc;

    a {
        //width: 300px;
        text-decoration: none;
    }
}
</style>