import {createRouter, createWebHashHistory} from 'vue-router'
// import HomeView from '@/views/HomeView.vue'
// import DetailView from "@/views/DetailView";
// import ClassifyView from "@/views/ClassifyView.vue";
// import TimeLineView from "@/views/TimeLineView.vue";
// import AboutView from "@/views/AboutView.vue";

import M_Home from "@/views/move/M_Home.vue";
import M_Detail from "@/views/move/M_Detail.vue";
import M_About from "@/views/move/M_About.vue";

const routesP = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('@/views/DetailView.vue')
  },
  {
    path: '/classify',
    name: 'classify',
    component: () => import('@/views/ClassifyView.vue')
  },
  {
    path: '/timeline',
    name: 'timeline',
    component: () => import('@/views/TimeLineView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/AboutView.vue')
  },
]

const routesM = [
  {
    path: '/',
    name: 'm_home',
    component: M_Home
  },
  {
    path: '/detail',
    name: 'm_detail',
    component: M_Detail
  },
  {
    path: '/about',
    name: 'm_about',
    component: M_About
  }
]


let routes = []
if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
  routes = routesM
} else {
  routes = routesP
}


const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})


export default router
