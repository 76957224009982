<template>
    <div class="container">
        <M_Bg></M_Bg>
        <div class="container_main">
            <M_Nav></M_Nav>
            <div class="container_main_list" v-for="item in listData" :key="item.id" @click="toDetail(item.id)">
                <img class="container_main_list_img" :src="item.avatar"/>
                <div class="container_main_list_content">
                    <div class="container_main_list_content_title">
                        {{ item.title }}
                    </div>
                    <div class="container_main_list_content_p">
                        {{ item.subtitle }}
                    </div>
                    <div class="container_main_list_content_tag">
                        <span>{{ formatDate(item.updated) }}</span>
                        <span>{{ item.category.name }}</span>
                    </div>
                </div>
            </div>
            <M_Footer></M_Footer>
        </div>
    </div>
</template>

<script setup>
import M_Bg from "@/views/move/components/M_Bg.vue";
import M_Nav from "@/views/move/components/M_Nav.vue";
import M_Footer from "@/views/move/components/M_Footer.vue";
import {onMounted, ref} from 'vue';
import {listInfo} from '@/api/base.js'
import {formatDate} from '@/utils/utils.js'
import {useRouter} from "vue-router";


// +++++++++++++++++++++++++++++++++++++++++++++
// 获取列表数据
// +++++++++++++++++++++++++++++++++++++++++++++
const listData = ref([])

function getListInfo() {
    listInfo().then(res => {
        listData.value = res.data.results
    })
}

// +++++++++++++++++++++++++++++++++++++++++++++
// 页面跳转
// +++++++++++++++++++++++++++++++++++++++++++++
const router = useRouter();

function toDetail(id) {
    const params = {id: id};
    router.push({
        name: 'm_detail',
        state: {
            params
        }
    })
}


onMounted(() => {
    getListInfo()
})

</script>

<style lang="scss" scoped>
.container {
  position: relative;
  top: 65px;
  padding: 0 20px 20px 20px;
  font-size: 14px;
  line-height: 24px;

  &_main {
    margin-bottom: 15vh;
    &_list {
      height: 100px;
      margin-top: 10px;

      background-color: white;
      opacity: 0.7;
      isolation: isolate;
      border-radius: 5px;
      padding: 7px;
      display: flex;

      &_img {
        width: 100px;
        height: 100%;

      }

      &_content {
        width: 90%;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        view {
          margin: 5px 0;
        }

        &_title {
          width: 100%;
          font-weight: bold;
          font-size: 16px;

        }

        &_p {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &_tag {
          font-weight: bold;

          span:nth-child(1) {
            float: left;
          }

          span:nth-child(2) {
            float: right;
          }
        }
      }

    }

  }
}
</style>