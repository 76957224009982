import axios from 'axios'

const request = axios.create({
  withCredentials: false,
    // baseURL: 'https://api.fcxh.club'
})

//请求拦截
request.interceptors.request.use(
  config => {
    return config
  },
  error => {
    return Promise.reject(new Error(error).message)
  }
)

//响应拦截
request.interceptors.response.use(
  response => {
    return response
  },
  error => {
    console.log('error', error)
    return Promise.reject(new Error(error).message)
  }
)

export default request
