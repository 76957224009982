<template>
	<div class="container_base">
		<div class="container_base_bg"></div>
	</div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
	.container {
		/* margin-top: 15%; */
		position: relative;
		top: 0;
		padding: 0 20px 20px 20px;
		font-size: 14px;
		line-height: 24px;

		&_base {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			&_bg {
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-image: url('@/assets/img/m_bg.png');
				background-size: 100% 100%;
			}
		}
	}
</style>