<template>
    <div class="container">
        <M_Bg></M_Bg>
        <div class="container_main">
            <M_Nav></M_Nav>
            <div class="container_mian_box">
                <div class="container_mian_box_content">
                    <div class="container_mian_box_title">
                        <span>{{ title }}</span>
                    </div>
                    <hr class="container_mian_box_hr"/>
                    <div class="container_mian_box_text" v-html="content"></div>
                </div>
            </div>
            <M_Footer></M_Footer>
        </div>
    </div>
</template>

<script setup>
import M_Bg from "@/views/move/components/M_Bg.vue";
import M_Nav from "@/views/move/components/M_Nav.vue";
import M_Footer from "@/views/move/components/M_Footer.vue";
import {detailInfo} from '@/api/base.js'
import {onMounted, ref} from 'vue';


const content = ref('')
const title = ref('')

function getDetail() {
    const historyParams = history.state.params
    detailInfo(historyParams.id).then(res => {
        title.value = res.data.title
        content.value = res.data.content
    })
}

onMounted(() => {
    getDetail()
})

</script>

<style lang="scss" scoped>
.container {
  position: relative;
  top: 65px;
  padding: 0 15px;
  font-size: 14px;
  line-height: 24px;
  text-align: start;

  &_mian {
    &_box {
      margin-top: 10px;
      margin-bottom: 15vh;
      background-color: white;
      opacity: 0.8;
      padding: 10px;

      &_title {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
      }

      &_hr {
        margin: 10px 20px;
        border: 1px solid #b9ecf5;
      }

      &_content {
        border: 1px solid #b9ecf5;
        min-height: 80vh;
        padding: 10px;
      }
    }
  }
}
</style>