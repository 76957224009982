<template>
    <div id="loading-animation">
        <img src="@/assets/heita.gif" alt="Loading...">
    </div>
    <SakuraLoad></SakuraLoad>
    <router-view />
  <!-- <div style="position: fixed;width: 100%;height: 100%; left:0; top: 0;">
      <div style="margin: 0;border: 0;top: 0;left: 0;width: 100%;height:100%;
      background-size:cover;background-position:top center;
      background-image: url('./index_bg.jpg');background-repeat:no-repeat;"></div>
    </div> -->
</template>
<script>
import SakuraLoad from "@/components/SakuraLoad.vue";
import FontFaceOnLoad from 'fontfaceonload';
import { onMounted, ref} from "vue";

export default {
    components: {
        SakuraLoad
    },
    setup() {
        // // 局部组件刷新
        // const isRouterAlive = ref(true);
        // const reload = () => {
        //     isRouterAlive.value = false;
        //     nextTick(() => {
        //         isRouterAlive.value = true;
        //     });
        // };
        // provide("reload", reload);

        onMounted(() => {
            const isfirstPageFontload = ref(false);
            new FontFaceOnLoad('HYQingYunW', {
              success: function() {
                // 字体加载完成
                isfirstPageFontload.value = true
                let loadingAnimation = document.getElementById('loading-animation');
                loadingAnimation.style.display = 'none'; // 隐藏加载动画
              }
            });
        })

        return {
            // isRouterAlive,
        };
    }
}
</script>

<style>
@font-face {
    font-family: HYQingYunW;
    src: url('./assets/HYQingYunW.woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    position: relative;
    margin: -8px;
}

nav {
    padding: 30px;
}

nav a {
    font-weight: bold;
    color: #2c3e50;
}

nav a.router-link-exact-active {
    color: #42b983;
}

#loading-animation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff; /* 或者任何你喜欢的背景色 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* 确保它位于其他内容之上 */
}
#loading-animation img {
    width: 200px; /* 或者任何你想要的尺寸 */
    height: auto;
}
</style>