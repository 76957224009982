import request from "@/utils/request";

export function listInfo(query) {
    return request({
        url: "/api/article/",
        method: 'get',
        params: query
    })
}

export function detailInfo(id) {
    return request({
        url: `/api/article/${id}/`,
        method: 'get',
    })
}

//评论
export function commentInfo(data) {
    return request ({
        url: '/api/comment/',
        method: 'post',
        data: data
    })
}

//获取评论
export function getCommentInfo(params) {
    return request ({
        url: `/api/comment/`,
        method: 'get',
        params: params
    })
}

//随机获取一条诗词信息
export function getRandomPoetry() {
    return request({
        url: "api/randomPoetry/",
        method: 'get',
    })
}