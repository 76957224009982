<template>
    <canvas class="god_top_canvas"></canvas>
    <img style="display: none;" class="this_sakura" src="../assets/sakura.png" alt="" srcset="">
</template>

<script>

export default {
    name: "SakuraLoad",
    data() {
        return {
            // 樱花大小随机基数
            sakura_size: 0.3,
            Sakura_list: [],
            // 樱花数量
            sakura_num: 10,
        }
    },
    mounted() {
        this.Sakura_lod();
    },
    methods: {

        initSakura() {
            let canvas = document.querySelector('.god_top_canvas');
            // 设置画布的宽高
            canvas.height = window.innerHeight;
            canvas.width = window.innerWidth;
            // 获取画笔
            let cxt = canvas.getContext('2d');
           // 单个花瓣img标签，后续要向canvas提供花瓣数据
            let this_sakura = document.querySelector('.this_sakura');

            return {
                this_sakura: this_sakura,
                canvas: canvas,
                cxt: cxt
            }

        },

        Sakura_lod() {
            for (let index = 0; index < this.sakura_num; index++) {
                let x = this.random('x');
                let y = this.random('y');
                let s = this.random('s');
                let r = this.random('r');
                let ob = {x, y, s, r};
                //加入到樱花数组
                this.Sakura_list.push(ob);
            }
            // 让樱花飘落
            this.action();
        },
        action() {
            let cxt = this.initSakura().cxt;
            let canvas = this.initSakura().canvas;
            // 清空画布，把上一次画的给清理了
            cxt.clearRect(0, 0, canvas.width, canvas.height);
            //更新樱花坐标
            this.update();
            //画
            this.draw();

            //反复执行，每一帧执行一次当前方法
            requestAnimationFrame(this.action);

        },
        update() {
            for (let index = 0; index < this.Sakura_list.length; index++) {
                let xx = this.Sakura_list[index].x;
                let yy = this.Sakura_list[index].y;
                let rr = this.Sakura_list[index].r;
                let ss = '';
                //如果樱花从屏幕上消失了
                if (xx > window.innerWidth || xx < 0 || yy > window.innerHeight || yy < 0) {

                    //为了防止右下角空白
                    if (Math.random() > 0.4) {
                        xx = this.random('x');
                        yy = 0;
                        ss = this.random('s');
                        rr = this.random('r');
                    } else {
                        xx = window.innerWidth;
                        yy = this.random('y');
                        ss = this.random('s');
                        rr = this.random('r');
                    }


                    if (this.sakura_size == 0) {
                        xx = window.innerWidth + 100;
                        yy = window.innerHeight + 100;
                        ss = 0;
                        rr = 0;
                    }
                    this.Sakura_list[index].x = xx;
                    this.Sakura_list[index].y = yy;
                    this.Sakura_list[index].s = ss;
                    this.Sakura_list[index].r = rr;
                } else {
                    //正常更新坐标
                    this.Sakura_list[index].x = this.random_n('x', xx);
                    this.Sakura_list[index].y = this.random_n('y', yy);
                    this.Sakura_list[index].r = this.random_n('r', rr);
                }
            }
        },
        draw() {
            let cxt = this.initSakura().cxt;
            let this_sakura = this.initSakura().this_sakura;
            // console.log(this_sakura);
            // return false;
            for (let index = 0; index < this.Sakura_list.length; index++) {
                // 横坐标
                let xx = this.Sakura_list[index].x;
                // 纵坐标
                let yy = this.Sakura_list[index].y;
                // 旋转角度
                let rr = this.Sakura_list[index].r;
                // 樱花大小
                let ss = this.Sakura_list[index].s;
                cxt.save();
                cxt.translate(xx, yy);
                cxt.rotate(rr);
                this_sakura = this_sakura || document.querySelector('.this_sakura')
                // return false;
                // console.log(this_sakura);
                cxt.drawImage(this_sakura, 0, 0, 40 * ss, 40 * ss)
                cxt.restore();
            }
        },

        // 生成各种类型的随机数，主要用来决定樱花的初始位置
        random(option) {
            if (option == 'x') {
                return Math.random() * window.innerWidth;
            } else if (option == 'y') {
                return Math.random() * window.innerHeight;
            } else if (option == 's') {
                if (Math.random() > 0.5) {
                    return this.sakura_size * 2.5;
                } else {
                    return Math.random();
                }
                // return sakura_size*2.1;
                // return Math.random();
            } else if (option == 'r') {
                return Math.random() * 6;
            } else {
                console.log('参数不对');
            }
        },

        // 决定樱花下一次该怎么飘落
        random_n(type, va) {
            if (type == 'x') {
                let random = -0.5 + Math.random() * 1;
                return va + 0.5 * random - 1.7;
            } else if (type == 'y') {
                let random = 0.5 + this.rand_sakura(this.sakura_size) * 0.7
                return va + random;
            } else if (type == 'r') {
                let random = (Math.random()) * 0.03;
                if (Math.random() < 0.4) {
                    random = (this.rand_sakura(this.sakura_size)) * 0.03;
                }
                return va + random;
            } else {
                console.log('参数不对');
            }
        },

        rand_sakura(ee) {

            let cci = ee * 2.2;

            if (ee == 0) {
                cci = Math.random();
            }
            return cci;
        }

    }

}
</script>

<style scoped>
.god_top_canvas {
    position: fixed;
    z-index: 999;
    left: 0;
    pointer-events: none;
}
</style>