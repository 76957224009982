import { createStore } from 'vuex'

export default createStore({
  state: {
    search: null
  },
  getters: {
    getSearch(state) {
      return state.search
    }
  },
  mutations: {
    setSearch(state, search) {
      state.search = search
    }
  },
  actions: {
  },
  modules: {
  }
})
