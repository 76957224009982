<template>
    <div>
        <div class="container-nav">
            <span class="container-nav_leftIcon" style="float: left;" @click="openLeftNav">
                <el-icon :size="22"><Expand/></el-icon>
            </span>
            <span class="container-nav_title">祈年安安</span>

        </div>
        <el-drawer
                v-model="left_popup_status"
                :direction="'ltr'" size="60%"
                :modal="true"
                :with-header="false"
                :z-index="111"
                @close="left_popup_status = false">
            <div class="container-nav-left">
                <div class="container-nav-left_header">
                    <div class="container-nav-left_header_bg"></div>
                    <img class="container-nav-left_header_img" src="https://q1.qlogo.cn/g?b=qq&nk=2715355163&s=100"
                         alt="#"/>
                    <div class="container-nav-left_header_name">祈年安安</div>
                    <hr class="container-nav-left_header_hr"/>
                    <div class="container-nav-left_header_signature">
                        且视他人之疑目如盏盏鬼火，大胆去走你的路
                    </div>
                </div>
                <div class="container-nav-left_menu">
                    <!-- <view class="container_main_left_menu_list">归档</view> -->
                    <div class="container-nav-left_menu_list" @click="toAbout">关于</div>
                </div>
            </div>
        </el-drawer>
    </div>

</template>

<script setup>
import {ref} from 'vue';
import {useRouter} from "vue-router";
// +++++++++++++++++++++++++++++++++++++++++++++
// 控制左侧菜单
// +++++++++++++++++++++++++++++++++++++++++++++
let left_popup_status = ref(false)
const openLeftNav = () => {
    if (left_popup_status.value === false) {
        left_popup_status.value = true
    } else if (left_popup_status.value === true) {
        left_popup_status.value = false
    }
}
// +++++++++++++++++++++++++++++++++++++++++++++
// 菜单调跳转
// +++++++++++++++++++++++++++++++++++++++++++++
const router = useRouter();
function toAbout() {
    router.push({
        name: 'm_about',
    })
}
</script>

<style lang="scss" scoped>
.container-nav {
  width: 100%;
  height: 55px;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  margin: 0 -20px 0 -20px;
  padding: 0 20px 0 20px;
  z-index: 200;
  position: fixed;
    top: 0;
    text-align: center;

  &_leftIcon {
    float: left;
    line-height: 65px;
  }

  &_title {
    line-height: 55px;
    font-size: 30px;
    font-family: HYQingYunW, serif;
  }
}

.container-nav-left {
  margin-top: 55px;

  &_header {
    width: 100%;
    // background-color: red;
    text-align: center;

    &_bg {
      background-image: url("@/assets/img/wen.jpg");
      object-fit: cover;
      background-position-x: center;
      background-position-y: center;
      background-size: cover;
      min-height: 150px;
      width: 100%;
    }

    &_img {
      position: absolute;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 65px;
      height: 65px;
      border-radius: 50%;
    }

    &_name {
      margin-top: 45px;
      font-size: 16px;
    }

    &_hr {
      margin: 10px 20px;
      border: 1px solid #dcdfe6;
    }

    &_signature {
      padding: 0 20px;
      font-family: 'HYQingYunW', serif;
    }
  }

  &_menu {
    padding: 15px 0;

    &_list {
      border-bottom: 1px solid #dcdfe6;
      height: 50px;
      line-height: 50px;
      padding: 0 20px;
    }

    &_list:nth-child(1) {
      border-top: 1px solid #dcdfe6;
    }

    &_list:active {
      background-color: #dcdfe6;
    }
  }

}

:deep(.el-drawer__body) {
  padding: unset;
}
</style>